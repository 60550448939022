import './util/modernizr';
import './util/polyfills';
import '../../style.scss';
import './core/core';

/*
 * Atoms
 */
import floatingCta from '../../patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import globalLoader from '../../patterns/01-atoms/global-loader/globar-loader-a/global-loader-a';
import scrollToTop from '../../patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import showAccordion from '../../patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import showAccordionB from '../../patterns/02-molecules/accordions/accordion-b/accordion-b-1';
import dropdownA from '../../patterns/02-molecules/dropdowns/dropdown-a/dropdown-a-1';
import dropdownB from '../../patterns/02-molecules/dropdowns/dropdown-b/dropdown-b-1';
import dropdownC from '../../patterns/02-molecules/dropdowns/dropdown-c/dropdown-c-1';
import multiselectDropdown from '../../patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import gallery from '../../patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import modal from '../../patterns/02-molecules/modals/modal-a/modal-a-1';
import modalVideoA1 from '../../patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import { hamburger } from '../../patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
// import navigateCategory from '../../patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navDropdown from '../../patterns/02-molecules/nav/nav-dropdown/nav-dropdown';
import navMob from '../../patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import searchBar from '../../patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from '../../patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import sliderImages from '../../patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
import sliderTestimonials from '../../patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from '../../patterns/02-molecules/social/social-share-a/social-share-a-1';
import tableA1 from '../../patterns/02-molecules/tables/table-a/table-a-1';
import tabs from '../../patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import widgetTranslation from '../../patterns/02-molecules/widgets/widget-translation/widget-translation';
import sliderBadgesB from '../../patterns/03-organisms/repeatable-custom/badges-b/badges-b-1/badges-b-1';
import cardsA1 from '../../patterns/03-organisms/repeatable-custom/cards-a/cards-a-1/cards-a-1';
import cardsA2 from '../../patterns/03-organisms/repeatable-custom/cards-a/cards-a-2/cards-a-2';
import cardsA3 from '../../patterns/03-organisms/repeatable-custom/cards-a/cards-a-3/cards-a-3';
import cardsB1 from '../../patterns/03-organisms/repeatable-custom/cards-b/cards-b-1/cards-b-1';
import cardsC1 from '../../patterns/03-organisms/repeatable-custom/cards-c/cards-c-1/cards-c-1';
import cardsD1 from '../../patterns/03-organisms/repeatable-custom/cards-d/cards-d-1/cards-d-1';
import cardsD2 from '../../patterns/03-organisms/repeatable-custom/cards-d/cards-d-2/cards-d-2';
import columnsA2 from '../../patterns/03-organisms/repeatable-custom/columns-a/columns-a-2/columns-a-2';
import eventsTypeA from '../../patterns/03-organisms/repeatable-custom/events-a/events-a-1/events-type-a-1';
import faqExpand from '../../patterns/03-organisms/repeatable-custom/faq-c/faq-c-1/faq-c-1';
import imagesB1 from '../../patterns/03-organisms/repeatable-custom/images-b/images-b-1/images-b-1';
import marqueeA from '../../patterns/03-organisms/repeatable-custom/marquee-a/marquee-a-1/marquee-a-1';
import testimonialC1 from '../../patterns/03-organisms/repeatable-custom/testimonial-c/testimonial-c-1/testimonial-c-1';
/*
 * Organisms
 */
import header from '../../patterns/03-organisms/single/header/header-1/header-1';
import hero1 from '../../patterns/03-organisms/single/hero/hero-1/hero-1';
import hero3 from '../../patterns/03-organisms/single/hero/hero-3/hero-3';
import hero4 from '../../patterns/03-organisms/single/hero/hero-4/hero-4';
import hero6 from '../../patterns/03-organisms/single/hero/hero-6/hero-6';
/*
 * Accordions
 */
import accordionMobileInit from './accordions/accordion-mobile-init';
import parallaxScroll from './animations/parallax-light';
import almLoadMore from './misc/alm-load-more/alm-load-more';
/*
 * Animations
 */
/*
 * Misc
 */
import externalLinks from './misc/external-links/external-links';
import scrollToAnchor from './misc/scroll/scroll-to-anchor';
/**
 * Fixing some accessibility issues
 */
import fixAccessibility from './util/accessibility';

/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    hero1,
    hero3,
    hero4,
    hero6,
    showAccordion,
    showAccordionB,
    sliderImages,
    sliderTestimonials,
    header,
    sliderBadgesB,
    searchBar,
    scrollToTop,
    floatingCta,
    socialShare,
    hamburger,
    slideOut,
    marqueeA,
    // debugControls,
    tableA1,
    tabs,
    // mapBlock,
    dropdownA,
    dropdownB,
    dropdownC,
    gallery,
    cardsA1,
    cardsA2,
    cardsA3,
    cardsB1,
    cardsC1,
    cardsD1,
    cardsD2,
    columnsA2,
    imagesB1,
    // filterType1,
    faqExpand,
    multiselectDropdown,
    navDropdown,
    eventsTypeA,
    almLoadMore,
    testimonialC1,
    modalVideoA1,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    scrollToAnchor($);
    widgetTranslation($);
    navMob($);
    externalLinks($);
    accordionMobileInit();

    // Executing it here in order to avoid duplications
    window.globalLoader = globalLoader();

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    // Encapsulates table instances that don't have ".table-responsive" parent in a div with table-responsive CSS class.
    $('.theme-table').each(function () {
        if (!$(this).parent().hasClass('table-responsive')) {
            $(this).wrap('<div class="table-responsive"></div>');
        }
    });

    modal($);

    // Fixing some accessibility issues on WP side
    fixAccessibility();
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
